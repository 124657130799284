body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.date-picker {
  margin-top: 10px;
  width: 276px;
  height: 48px;
  border-radius: 5px;
  font-size: 24px;
}

.second-date-picker {
  margin-left: 30px;
  margin-top: 10px;
  width: 276px;
  height: 48px;
  border-radius: 5px;
  font-size: 24px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop,
.css-1fhn46u-MuiModal-root-MuiDialog-root {
  right: 45px !important;
  left: 25px !important;
  top: 18px !important;
  bottom: 20px !important;
  border-radius: 20px;
  background-color: rgba(26, 52, 156, 0.4) !important;
  overflow-y: auto;
}

.select_input {
  background: rgba(38, 82, 156, 0.07) url(./assets/icon/arrow-down.svg)
    no-repeat scroll calc(93%) center/10px auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.searchItem {
  border-radius: 120px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.searchItem.active {
  background: #fff;
  -webkit-box-shadow: 0 0 5px 5px hsla(210, 8%, 62%, 0.035);
  box-shadow: 0 0 5px 5px hsla(210, 8%, 62%, 0.035);
}

.searchItem__title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-transform: capitalize;
  color: #000;
  margin-bottom: 8px;
  white-space: nowrap;
}
.searchItem__input {
  position: relative;
  width: 100%;
}

.searchItem__room {
  position: absolute;
  left: 0;
  top: calc(100% + 4px);
  background: #fff;
  border-radius: 12px;
  -webkit-box-shadow: 0px 10px 55px rgba(42, 45, 131, 0.13);
  box-shadow: 0px 10px 55px rgba(42, 45, 131, 0.13);
  z-index: 5;
  width: 356px;
}
.searchItem__room-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 12px 24px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 12px 12px 0 0;
}
.searchItem__room-head-reset {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #dbdde1;
  background: none;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
}
.searchItem__room-head-reset:hover {
  opacity: 0.8;
}
.searchItem__room-head-add {
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  background: #26529c;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color: #fff;
  padding: 10px 14px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.searchItem__room-head-add:hover {
  opacity: 0.8;
}
.searchItem__room-item-title {
  background: #ededed;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #0a1121;
  padding: 12px 24px 13px 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.searchItem__room-item-title button {
  background: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  width: 10px;
  height: 10px;
}
.searchItem__room-item-title button:hover {
  opacity: 0.8;
}
.searchItem__room-item-title button svg {
  width: 100%;
  height: 100%;
  color: #000;
  pointer-events: none;
}
.searchItem__room-item-content {
  padding: 16px 24px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.searchItem__room-item-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 16px;
}
.searchItem__room-item-row h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #000;
}
.searchItem__room-item-age p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000;
  margin-bottom: 12px;
}
.searchItem__room-item-age-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: calc(100% + 12px);
  margin: 0 -6px;
}
.searchItem__room-item-age-items select {
  width: calc(33.33% - 12px);
  margin: 0 6px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  padding: 9px 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #dbdde1;
  border-radius: 4px;
}
.searchItem__room-spinbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.searchItem__room-spinbox-opr {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #26529c;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  cursor: pointer;
  margin-left: 16px;
}
.searchItem__room-spinbox-opr:hover {
  opacity: 0.8;
}
.searchItem__room-spinbox-opr.disabled {
  opacity: 0.24;
}
.searchItem__room-spinbox-opr svg {
  width: 14px;
  height: 14px;
  color: #26529c;
}
.searchItem__room-spinbox-opr:first-child {
  margin-right: 16px;
  margin-left: 0;
}
.searchItem__room-spinbox-opr:first-child svg {
  width: 14px;
  height: 2px;
}
.searchItem__room-spinbox-num {
  width: 40px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #dbdde1;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000;
}
.box-level {
  display: flex;
  align-items: stretch;
}

/* Calendar Range Input Css  */

.rs-input-group {
  height: 100% !important;
  border-radius: 24px !important;
  border: none !important;
  outline: none !important;
}

.rs-input {
  background-color: transparent !important;
  border: none !important;
  border-radius: 24px !important;
  height: 100% !important;
}

.rs-input-group-addon {
  font-size: 20px !important;
}
/* Add these styles to your CSS file */

.status-confirmed {
  background-color: #53D969 !important;
  color: #FFFFFF !important;
}

.status-waiting {
  background-color: #3984F3 !important;
  color: #FFFFFF !important;
}

.status-cancelled {
  background-color: #FE4A55 !important;
  color: #FFFFFF !important;
}
.status-quote {
  background-color: #2151F8 !important;
  color: #FFFFFF !important;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  /* Dark overlay */
  display: flex;
  flex-direction: column;
  /* Align children vertically */
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure it's above other elements */
}

.loader {
  border: 5px solid #f3f3f3;
  /* Light grey */
  border-top: 5px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}

.loader-text {
  color: #fff;
  font-size: 18px;
  /* Adjust font size as needed */
  text-align: center;
  margin-top: 20px;
  /* Space between loader and text */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
