.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.customDataGrid .MuiDataGrid-viewport {
  overflow-x: auto;
}

.customDataGrid .MuiDataGrid-columnsContainer {
  border-right: 1px solid #e0e0e0;
}

.customDataGrid .MuiDataGrid-colCellWrapper {
  border-right: 1px solid #e0e0e0;
  background-color: #f5f6fa;
}

.customDataGrid .MuiDataGrid-cell {
  /* border-right: 1px solid #e0e0e0; */
  /* background-color: #f5f6fa; */
}

@media (max-width: 600px) {
  .customDataGrid .MuiDataGrid-row:last-child .MuiDataGrid-cell {
    border-bottom: none;
    /* Remove border for the last row */
  }
}

.css-1h7na2a-MuiDataGrid-root .MuiDataGrid-columnHeaderDraggableContainer {
  /* background-color: #e3e9f2; */
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow {
  /* background-color: #e3e9f2; */
}

.css-1h7na2a-MuiDataGrid-root .MuiDataGrid-withBorderColor {
  border-color: rgba(224, 224, 224, 1);
  /* background-color: #f4f6fa; */
}

.css-1iyq7zh-MuiDataGrid-columnHeaders {
  background-color: #e3e9f2;
}

.css-128fb87-MuiDataGrid-toolbarContainer {
  height: 50px;
  background-color: #e3e9f2;
}

.css-1h7na2a-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  overflow: hidden;
  white-space: nowrap;
  border: 0.5px solid #26529c;
}

.css-wop1k0-MuiDataGrid-footerContainer {
  display: block;
  margin: auto;
}
.input-cls {
  width: 100% !important;
}

.rti--input {
  border: 0;
  font-size: inherit;
  line-height: inherit;
}

.rti--container {
  width: 876px;
  min-height: 48px;
}

/* .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;  
}*/

/* .modal-content {
  background-color: white;
  width: 712px;
  height: 540px;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  z-index: 1000; 
} */

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-title {
  font-size: 32px;
  font-weight: 600;
}

.modal-body {
  display: flex;
  flex-direction: column;
}

.image-container {
  position: relative;
}

.delete-icon {
  position: absolute;
  top: 10px;
  right: 20px;
}

.input-container {
  /* Style your input container */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  width: 712px;
  height: 700px;
  border-radius: 10px;
  overflow: hidden;
}

/* .modal-content::-webkit-scrollbar {
  width: 10px;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
} */

.error-message {
  color: red;
  padding: 5px 0px;
}

.pagination_menu {
  width: 100%;
  align-items: center;
  border: 1px solid rgba(24, 29, 31, 0.15);
  padding: 15px;
  border-radius: 6px;
  display: flex;
  gap: 45px;
  justify-content: end;
}

.pagination_count {
  display: flex;
  align-items: center;
  gap: 3px;
}

.page_size_part {
  display: flex;
  align-items: center;
  gap: 3px;
  color: #181d1f;
}
.pagination_font {
  color: #181d1f;
}
.button.Reqprimary {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
  padding: 13px 80px;
  background-color: #ed0000;
  border-radius: 10px;
}

.button.ReqDprimary {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
  padding: 13px 80px;
  background-color:#25529C ;
  border-radius: 10px;
}